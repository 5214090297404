import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import { toast } from '@jill64/svelte-toast'
import { get } from 'svelte/store'

const onError = clientInit(PUBLIC_SENTRY_DSN, { enableInDevMode: false});

export const handleError = onError((e) => {
  // Your Error Handler
  console.error(e)
  if (e.error instanceof Error && process.env.NODE_ENV != 'development') {
    get(toast).error(e.error.message)
  }
})